/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import 'bootstrap/dist/css/bootstrap.min.css'
import 'styles/fonts.css'

import Footer from 'components/Footer'
import Header from 'components/Header'
import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import globalStyles from 'styles/global'
import { sessionStore } from '../../utils/StorageUtils'

const styles = createUseStyles(globalStyles)

type Props = {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  styles()

  const partnerId = sessionStore?.getItem('partnerId')

  useEffect(() => {
    if (partnerId && typeof document !== 'undefined') {
      document.body.classList.add('page-shown-in-iframe')
    }
  }, [partnerId])

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default Layout
