import { Button, Col, Image, Row } from "react-bootstrap"

import { Link } from "gatsby"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  wrapper: {
    maxWidth: "1140px",
    height: "242px",
    boxShadow:
      "11px 11px 20px rgba(166, 171, 189, 0.4), -11px -11px 20px #fafbff",
    borderRadius: "10px",
    backgroundColor: "#eff0f3",
    margin: "auto",
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      height: "initial",
      padding: "10px",
    },
  },
  title: {
    maxWidth: "488px",
    height: "62px",
    color: theme.colors.darkGrey,
    fontFamily: "Nunito",
    fontSize: "24px",
    fontWeight: 700,
    letterSpacing: "1px",
    marginTop: "50px",
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      height: "initial",
      maxWidth: "100%",
    },
  },

  description: {
    maxWidth: "450px",
    height: "46px",
    color: theme.colors.darkGrey,
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 300,
    letterSpacing: "0.67px",
    marginTop: "20px",
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      height: "initial",
      maxWidth: "100%",
    },
  },

  loadButtonWrapper: {
    width: "100%",
    textAlign: "center",
    paddingTop: "110px",
    paddingBottom: "20px",
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      paddingTop: "50px",
    },
  },

  loadButton: {
    height: "41px",
    boxShadow: "2px 2px 5px rgba(80, 178, 125, 0.51), -2px -2px 5px #ffffff",
    borderRadius: "10px",
    backgroundColor: theme.colors.primaryGreen,
    padding: "12px 20px 12px 20px",
    "&:hover": {
      backgroundColor: "#2bad47",
      textDecoration: "none",
    },
    "&:focus": {
      outline: "none !important",
      boxShadow: "none",
    },
    "&:active": {
      outline: "none !important",
      boxShadow: "none",
    },
    "& span": {
      color: "#ffffff",
      fontFamily: "Nunito",
      fontSize: "13px",
      fontWeight: 700,
      letterSpacing: "1px",
      textTransform: "uppercase",
      padding: "5px",
    },
  },
  main: {
    width: "100%",
    marginTop: "50px",
  },
  noPaddingMargin: {
    margin: 0,
    padding: 0,
  },

  icon: {
    margin: 0,
    padding: 0,
    [`@media (max-width: ${theme.breakpoints.M})`]: {
      display: "none",
    },
  },

  content: {
    margin: 0,
    paddingLeft: "25px",
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      paddingLeft: "0px",
    },
  },
}))

type Props = {
  title: string
  description: string
  buttonText: string
  buttonLink: string
  icon: any
}

const ShopsFooter: React.FC<Props> = props => {
  const classes = useStyles()
  const { title, description, buttonText, buttonLink, icon } = props
  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <Row className={classes.noPaddingMargin}>
          <Col sm={12} md={12} lg={3} className={classes.icon}>
            <Image
              src={icon}
              style={{
                marginLeft: "60px",
                marginTop: "28px",
              }}
            />
          </Col>

          <Col sm={12} md={8} lg={6} className={classes.content} style={{}}>
            <div className={classes.title}>{title}</div>
            <div className={classes.description}>{description}</div>
          </Col>
          <Col sm={12} md={4} lg={3} className={classes.noPaddingMargin}>
            <div className={classes.loadButtonWrapper}>
              <Link to={buttonLink} className={classes.loadButton}>
                <span>{buttonText}</span>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ShopsFooter
