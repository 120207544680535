import { Image } from "react-bootstrap"
import React from "react"
import { createUseStyles } from "react-jss"
import { useTranslation } from "react-i18next"

const testImage = require("../../images/schodisko.jpg")

const styles = createUseStyles(theme => ({
  wrapper: {
    borderRadius: "10px",
    display: "block",
    height: 340,
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "25px 0",
    position: "relative",
    "&:hover > div": {
      opacity: 1,
    },
  },
  socialIcon: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderRadius: "0 8px 0 10px",
    background: theme.colors.primaryGreen,
    "& i:before": {
      color: "white",
    },
  },
  description: {
    opacity: 0,
    cursor: "pointer",
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.9)",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    padding: 25,
    flexDirection: "column",
  },
  text: {
    color: theme.colors.darkGrey,
    fontWeight: 100,
    lineHeight: "24px",
    fontSize: 14,
    maxHeight: 120,
    overflow: "hidden",
    maxWidth: "100%",
  },
  button: {
    height: 45,
    padding: "0 20px",
    lineHeight: "45px",
    borderRadius: 10,
    fontWeight: 600,
    backgroundColor: "#EBECF0",
    boxShadow: "-2px -2px 3px 0 #FFFFFF, 2px 2px 3px 0 #A6ABBD",
    color: theme.colors.darkGrey,
    textTransform: "uppercase",
  },
}))

type SocialThumbnailProps = {
  image: string
  text: string
  link: string
}

const SocialThumbnail: React.FC<SocialThumbnailProps> = ({
  image,
  text,
  link,
}) => {
  const classes = styles()
  const { t } = useTranslation()

  return (
    <a
      target="_blank"
      className={classes.wrapper}
      style={{ backgroundImage: `url(${image})` }}
      href={link}
    >
      <div className={classes.socialIcon}>
        <i className="icon-schneider"></i>
      </div>
      <div className={classes.description}>
        <p className={classes.text}>{text}</p>
        <span className={classes.button}>{t("SHOW_MORE")}</span>
      </div>
    </a>
  )
}

export default SocialThumbnail
