import { gql } from "@apollo/client"

export const BLOG_CATEGORIES_QUERY = gql`
  query {
    blogCategories {
      Name
      id
    }
  }
`
