import Blog from '../views/Blog'
import Layout from '../components/Layout'
import React from 'react'
import SEO from '../components/seo'

const BlogPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Blog blogUrl={location?.href} />
    </Layout>
  )
}

export default BlogPage
