import { Col, Image, Row } from 'react-bootstrap'
import React, { useState } from 'react'

import ClipboardIcon from 'images/icons/clipboard.svg'
import FacebookIcon from 'images/icons/facebook.svg'
import { Helmet } from 'react-helmet'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

const leftFrameBigIcon = require('../../images/leftFrameBig.svg')

const useStyles = createUseStyles((theme) => ({
  title: {
    height: '112px',
    color: theme.colors.darkGrey,
    fontFamily: 'Nunito',
    fontSize: '50px',
    fontWeight: '600',
    lineHeight: '57px',
    maxWidth: (props) => (props.icon ? '500px' : 'auto'),
    display: 'table-cell',
    verticalAlign: 'middle',
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      fontSize: '36px',
    },
    [`@media (max-width: ${theme.breakpoints.XS})`]: {
      fontSize: '26px',
    },
  },
  wrapper: {
    maxWidth: '1280px',
    paddingTop: 60,
    margin: 'auto',
    maxHeight: '380px',
    pointerEvents: (props) => (props.shareButtons ? 'auto' : 'none'),
  },
  noPaddingMargin: {
    margin: 0,
    padding: 0,
  },

  row: {
    margin: 0,
    padding: 0,
    height: '350px',
  },
  leftCol: {
    margin: 0,
    paddingTop: '100px',
    paddingRight: '5px',
  },
  centerCol: {
    margin: 0,
    paddingTop: '100px',
  },
  rightCol: {
    margin: 0,
    padding: 0,
    textAlign: 'right',
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      display: 'none',
    },
  },
  shareButtons: {
    display: 'flex',
    marginLeft: 'auto',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& a': {
      display: 'inline-block',
      padding: '3px 6px',
      margin: '0 3px',
      cursor: 'pointer',
      position: 'relative',
    },
  },
  hiddenInput: {
    height: 0,
    width: 0,
    opacity: 0,
  },
  clipboardTooltip: {
    position: 'absolute',
    right: 'calc(100% + 10px)',
    top: 0,
    bottom: 0,
    padding: '10px',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    background: theme.colors.primaryGreen,
    '&:before': {
      content: `""`,
      position: 'absolute',
      right: -5,
      transform: 'rotate(45deg)',
      width: 10,
      height: 10,
      background: theme.colors.primaryGreen,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
}))

type Props = {
  title: string
  icon?: any
  retinaIcon?: boolean
  shareButtons?: boolean
  MetaDescription?: string
  MetaTitle?: string
  image?: string
  url?: string
}

const ShopsHeader: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const {
    icon,
    title,
    retinaIcon,
    shareButtons,
    MetaDescription,
    MetaTitle,
    image,
    url,
  } = props

  const [tooltipVisible, toggleTooltip] = useState(false)
  const { t } = useTranslation()

  const meta = (
    <Helmet
      meta={[
        {
          property: `og:title`,
          content: MetaTitle,
        },
        {
          property: `og:description`,
          content: MetaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:url`,
          content: url,
        },
      ]}
    />
  )

  const share = () => {
    const e = document.createElement('input')
    e.style.position = 'absolute'
    e.style.left = '-100000px'
    e.style.top = '-10000000px'
    e.style.opacity = '0'
    e.value = url
    document.body.appendChild(e)
    e.select()
    e.setSelectionRange(0, 99999)
    document.execCommand('copy')
    document.body.removeChild(e)
    showTooltip()
  }

  const showTooltip = () => {
    toggleTooltip(true)
    setTimeout(() => toggleTooltip(false), 5000)
  }

  return (
    <div className={classes.wrapper}>
      <Row className={classes.row}>
        <Col
          xs={1}
          sm={1}
          md={1}
          className={classes.leftCol}
          style={
            shareButtons ? { paddingTop: '50px' } : { paddingTop: '100px' }
          }
        >
          <Image src={leftFrameBigIcon} style={{ float: 'right' }} />
        </Col>
        <Col
          xs={shareButtons ? 10 : 10}
          sm={shareButtons ? 8 : 5}
          className={classes.centerCol}
          style={
            shareButtons ? { paddingTop: '50px' } : { paddingTop: '100px' }
          }
        >
          <h1
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h1>
        </Col>
        {shareButtons && (
          <Col xs={12} sm={2}>
            {meta}
            <div className={classes.shareButtons}>
              <a
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              >
                <img src={FacebookIcon} />
              </a>
              <a onClick={() => share()}>
                <img src={ClipboardIcon} />
                {tooltipVisible && (
                  <span className={classes.clipboardTooltip}>
                    {t('COPIED')}
                  </span>
                )}
              </a>
            </div>
          </Col>
        )}
        {icon && (
          <Col xs={1} sm={6} className={classes.rightCol}>
            <Image
              src={icon}
              style={{
                marginRight: '80px',
                maxHeight: retinaIcon ? '267px' : '100%',
                maxWidth: '100%',
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default ShopsHeader
