import { gql } from "@apollo/client"

export const BLOG_ARTICLES_QUERY = gql`
  query blogPosts($startFrom: Int, $where: JSON, $limit: Int) {
    blogPostsCount(where: $where)
    blogPosts(
      limit: $limit
      start: $startFrom
      where: $where
      sort: "PublicationDate:DESC"
    ) {
      id
      Title
      PublicationDate
      Slug
      Content
      updated_at
      Perex
      Tags {
        Name
      }
      Categories {
        HidePublicationDates
      }
      CoverPhoto {
        formats
        url
      }
      Gallery {
        id
        url
        formats
      }
    }
  }
`
