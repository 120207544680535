import { Col, Container, Row } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'

import { ArticleThumbnail } from '../components/Article'
import { BLOG_ARTICLES_PER_PAGE } from '../constants/paginations'
import { BLOG_ARTICLES_QUERY } from '../apollo/queries/blogsQuery'
import { BLOG_CATEGORIES_QUERY } from '../apollo/queries/blogsCategoriesQuery'
import Pagination from '../components/Pagination'
import ShopsFooter from '../components/Shops/ShopsFooter'
import ShopsHeader from '../components/Shops/ShopsHeader'
import SocialThumbnail from '../components/Thumbnail/SocialThumbnail'
import Tabs from '../components/Tabs'
import { createUseStyles } from 'react-jss'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const contactIcon = require('../images/@0,5xcomment.png')
const electriciansHeaderIcon = require('../images/zasuvka.png')

const useStyles = createUseStyles((theme) => ({
  title: {
    color: theme.colors.darkGrey,
    fontSize: '24px',
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 150,
    marginBottom: 30,
  },
  loadButtonWrapper: {
    textAlign: 'center',
    marginTop: 20,
  },
  loadButton: {
    cursor: 'pointer',
    height: '41px',
    boxShadow: '2px 2px 5px rgba(80, 178, 125, 0.51), -2px -2px 5px #ffffff',
    borderRadius: '10px',
    color: 'white',
    backgroundColor: theme.colors.primaryGreen,
    padding: '12px 20px 12px 20px',
    '&:hover': {
      backgroundColor: '#2bad47',
      textDecoration: 'none',
    },
    '&:focus': {
      outline: 'none !important',
      boxShadow: 'none',
    },
    '&:active': {
      outline: 'none !important',
      boxShadow: 'none',
    },
    '& span': {
      color: '#ffffff',
      fontFamily: 'Nunito',
      fontSize: '13px',
      fontWeight: 700,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      padding: '5px',
    },
  },
  fixedSpacer: {
    height: 61,
    margin: '40px 0',
  },
}))

const Blog: React.ComponentType<{ blogUrl: string }> = ({ blogUrl }) => {
  const { t } = useTranslation()
  const [activeCategory, changeActiveCategory] = useState(null)
  const [startFrom, changeStartFrom] = useState(0)
  const [pagesLimit, changePagesLimit] = useState(BLOG_ARTICLES_PER_PAGE)
  const [facebookPosts, setFacebookPosts] = useState([])
  const [searchQuery, setSearchQuery] = useState(null)
  const [blogPosts, setBlogPosts] = useState(null)
  const [isStickyFilter, setIsStickyFilter] = useState(false)
  const navbarElement = useRef(null)

  const filtersRef = useRef(null)
  const classes = useStyles()

  const handleScroll = () => {
    let offset = 0
    if (filtersRef.current) {
      setIsStickyFilter(
        filtersRef.current.getBoundingClientRect().top - offset <= 0,
      )
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const postsCondition = { Visible: true }
  Object.assign(
    postsCondition,
    searchQuery === '' ? null : { Perex_contains: searchQuery },
  )

  const { data } = useQuery(BLOG_ARTICLES_QUERY, {
    variables: {
      startFrom,
      where:
        activeCategory === null
          ? postsCondition
          : { Categories: activeCategory, ...postsCondition },
      limit: pagesLimit,
    },
  })

  const { data: categories } = useQuery(BLOG_CATEGORIES_QUERY)

  const handleLoadMore = () => {
    changePagesLimit(pagesLimit + BLOG_ARTICLES_PER_PAGE)
  }

  const handleChangeCategory = (categoryId) => {
    changeActiveCategory(categoryId)
    if (isStickyFilter) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (!data) return
    setBlogPosts(data)
  }, [data, activeCategory])

  useEffect(() => {
    fetch(
      `https://graph.facebook.com/v8.0/vypinac.cz/posts?fields=message%2Cid%2Cpermalink_url%2Cfull_picture&limit=6&access_token=${process.env.GATSBY_FACEBOOK_ACCESS_TOKEN}`,
    )
      .then((response) => response.json())
      .then((json) => {
        setFacebookPosts(json.data)
      })
  }, [])

  const handleChangeSearch = (query) => {
    if (query && (query === '' || query.length < 3)) {
      return setSearchQuery(null)
    }

    setSearchQuery(query)
  }

  return (
    <div>
      <ShopsHeader
        retinaIcon={true}
        title={t('BLOG_TITLE')}
        icon={electriciansHeaderIcon}
      />
      <Container>
        {categories && (
          <Row>
            <Col>
              <div className={classes.fixedSpacer}>
                <div ref={filtersRef}>
                  <Tabs
                    onChange={(categoryId) => handleChangeCategory(categoryId)}
                    onSearchChange={(query) => handleChangeSearch(query)}
                    items={categories.blogCategories}
                    defaultActiveId={null}
                    withSearch={true}
                    isSticky={isStickyFilter}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row ref={navbarElement}>
          <Col>
            {blogPosts &&
              blogPosts.blogPosts.map((post) => (
                <ArticleThumbnail
                  blogUrl={blogUrl}
                  slug={post.Slug}
                  key={post.Title}
                  title={post.Title}
                  perex={post.Perex}
                  galleryImages={post.Gallery}
                  image={post.CoverPhoto}
                  tags={post.Tags}
                  date={post.PublicationDate}
                  hideDate={post?.Categories?.[0]?.HidePublicationDates}
                />
              ))}
          </Col>
        </Row>
        {blogPosts && blogPosts.blogPostsCount > blogPosts.blogPosts.length && (
          <div className={classes.loadButtonWrapper}>
            <span
              className={classes.loadButton}
              onClick={() => handleLoadMore()}
            >
              {t('LOAD_NEXT_10_ITEMS')}
            </span>
          </div>
        )}
        <Row>
          <Col>
            <h2 className={classes.title}>{t('WHATS_NEW')}</h2>
          </Col>
        </Row>
        <Row>
          {facebookPosts &&
            facebookPosts.map((item) => (
              <Col key={item.id} xs={12} md={4}>
                <SocialThumbnail
                  image={item.full_picture}
                  text={item.message}
                  link={item.permalink_url}
                />
              </Col>
            ))}
        </Row>
        <Row>
          <Col>
            <ShopsFooter
              icon={contactIcon}
              buttonText={t('CONTACT_US')}
              buttonLink="/kontakty"
              description={t('WE_HELP_YOU_DIRECTLY')}
              title={t('YOU_DONT_FIND_WHAT_YOU_NEED')}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Blog
