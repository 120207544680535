import React, { useState } from 'react'

import ClipboardIcon from 'images/icons/clipboard.svg'
import FacebookIcon from 'images/icons/facebook.svg'
import { SRLWrapper } from 'simple-react-lightbox'
import SimpleReactLightbox from 'simple-react-lightbox'
import { createUseStyles } from 'react-jss'
import { getImage } from '../../helpers/image'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'

const styles = createUseStyles((theme) => ({
  wrapper: {
    background: 'linear-gradient(to right, #F7F8FA 0%, #EFEFF3 100%)',
    borderRadius: '10px',
    padding: '9px',
    margin: '30px 0',
    display: 'flex',
    '&:hover': {
      textDecoration: 'none',
      boxShadow: '0 0 1px rgba(0,0,0,.35)',
    },
  },
  left: {
    width: 260,
  },
  right: {
    padding: '20px 0 20px 20px',
    flex: 1,
  },
  image: {
    width: 260,
    cursor: 'pointer',
    height: 260,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    display: 'inline-block',
    '& img': {
      display: 'none',
    },
  },
  heading: {
    letterSpacing: '0.75px',
    fontWeight: 700,
    fontSize: 17,
    color: theme.colors.darkGrey,
    textTransform: 'inherit',
  },
  title: {
    cursor: 'pointer',
    display: 'flex',
  },
  icons: {
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    '& a': {
      display: 'inline-block',
      padding: '3px 6px',
      margin: '0 3px',
      position: 'relative',
    },
  },
  breadcrumb: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.5px',
    color: theme.colors.darkGrey,
    marginBottom: 20,
  },
  productClassList: {
    listStyleType: 'none',
    display: 'inline-block',
    margin: '0 0 0 15px',
    padding: 0,
    '& li': {
      display: 'inline-block',
      textTransform: 'uppercase',
      padding: '0 15px',
      borderLeft: `1px solid ${theme.colors.lightGrey}`,
      color: theme.colors.darkGrey,
      fontSize: 11,
    },
  },
  description: {
    color: theme.colors.darkGrey,
    fontSize: 14,
    letterSpacing: '0.58px',
    fontWeight: 100,
    lineHeight: '24px',
    maxHeight: 120,
    overflow: 'hidden',
  },
  gallery: {
    height: 'auto',
    marginTop: 20,
  },
  galleryItem: {
    height: 100,
    width: 100,
    display: 'inline-block',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginRight: '7px',
    borderRadius: '10px',
    position: 'relative',
    '& img': {
      height: 100,
      width: 100,
      opacity: 0,
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  clipboardTooltip: {
    position: 'absolute',
    right: 'calc(100% + 10px)',
    top: 0,
    bottom: 0,
    padding: '10px',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    background: theme.colors.primaryGreen,
    '&:before': {
      content: `""`,
      position: 'absolute',
      right: -5,
      transform: 'rotate(45deg)',
      width: 10,
      height: 10,
      background: theme.colors.primaryGreen,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
  '@media (max-width: 736px)': {
    wrapper: {
      flexDirection: 'column',
    },
    left: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))

type tagsArray = {
  Name: string
}

type ThumbnailProps = {
  title: string
  perex: String
  image: any
  date: any
  galleryImages?: any
  tags: tagsArray[]
  slug: String
  blogUrl: string
  hideDate: boolean
}

const ArticleThumbnail: React.FC<ThumbnailProps> = (props) => {
  const [tooltipVisible, toggleTooltip] = useState(false)
  const { t } = useTranslation()

  const classes = styles()
  const {
    perex,
    image,
    date,
    galleryImages,
    title,
    tags,
    slug,
    blogUrl,
    hideDate,
  } = props

  const navigateToDetail = () => {
    navigate(`/blog/${slug}`)
  }

  const share = (element) => {
    element.stopPropagation()
    const e = document.createElement('input') as HTMLInputElement
    e.style.cssText =
      'position: absolute; left: -100000px; top: -10000000px; opacity: 0'
    e.value = blogUrl + slug
    document.body.appendChild(e)
    e.select()
    e.setSelectionRange(0, 99999)
    document.execCommand('copy')
    document.body.removeChild(e)
    showTooltip()
  }

  const showTooltip = () => {
    toggleTooltip(true)
    setTimeout(() => toggleTooltip(false), 5000)
  }

  return (
    <article className={classes.wrapper}>
      <div className={classes.left}>
        <div
          onClick={() => navigateToDetail()}
          className={classes.image}
          style={{
            backgroundImage: `url(${getImage(image, 'medium')})`,
          }}
        >
          <img src={getImage(image, 'thumbnail')} alt={title} />
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.title}>
          <a onClick={() => navigateToDetail()}>
            <h2 className={classes.heading}>{title}</h2>
          </a>
          <div className={classes.icons}>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                blogUrl + slug
              }`}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              <img src={FacebookIcon} />
            </a>
            <a onClick={(e) => share(e)}>
              <img src={ClipboardIcon} />
              {tooltipVisible && (
                <span className={classes.clipboardTooltip}>{t('COPIED')}</span>
              )}
            </a>
          </div>
        </div>
        <div className={classes.breadcrumb}>
          <span>
            {!hideDate &&
              date &&
              new Date(date).toLocaleString('cs-CZ', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              })}
          </span>
          <ul className={classes.productClassList}>
            {tags.map((tag) => (
              <li key={tag.Name}>{tag.Name}</li>
            ))}
          </ul>
        </div>
        <div className={classes.description}>{perex}</div>
        <div className={classes.gallery}>
          <SimpleReactLightbox>
            <SRLWrapper>
              {galleryImages &&
                galleryImages.map((image) => (
                  <a
                    onClick={(e) => e.stopPropagation()}
                    href={getImage(image, 'large')}
                    key={image.url}
                    className={classes.galleryItem}
                    style={{
                      backgroundImage: `url(${getImage(image, 'thumbnail')})`,
                    }}
                  >
                    <img src={getImage(image, 'thumbnail')} alt={title}></img>
                  </a>
                ))}
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </div>
    </article>
  )
}

export default ArticleThumbnail
