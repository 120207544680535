import React, { useEffect, useState } from "react"

import { createUseStyles } from "react-jss"
import { useTranslation } from "react-i18next"

const styles = createUseStyles(theme => ({
  wrapper: {
    margin: 0,
    textAlign: "center",
    fontFamily: "'Nunito', sans-serif",
    position: sticky => (sticky ? "fixed" : "relative"),
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1000,
  },
  searchWrapper: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    background: "white",
    zIndex: 100,
    borderRadius: "10px",
    alignItems: "center",
    padding: 6,
    "& i": {
      "&:before": {
        color: "white",
        fontSize: 26,
        position: "relative",
        top: 7,
      },
    },
  },
  listWrapper: {
    display: "inline-block",
    position: "relative",
  },
  list: {
    listStyleType: "none",
    display: "inline-block",
    background: "#F8F8F8",
    padding: "5px",
    position: "relative",
    margin: 0,
    borderRadius: sticky => (sticky ? "0 0 10px 10px" : "10px"),
    boxShadow: sticky => (sticky ? "0px 0px 5px rgba(0,0,0,.25)" : ""),
  },
  item: {
    display: "inline-block",
    color: theme.colors.primaryGrey,
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 700,
    cursor: "pointer",
    borderRadius: "10px",
    padding: "15px 30px",
    marginRight: "10px",
    "&:hover": {
      color: theme.colors.primaryGreen,
    },
    "&:last-of-type": {
      marginRight: 0,
    },
    "&.active": {
      boxShadow: "inset 2px 2px 4px 0 rgba(166,171,189,0.3)",
      background: "#F0F3F8",
    },
  },
  search: {
    marginLeft: 15,
    color: "white",
    display: "inline-block",
    width: "44px",
    height: "44px",
    lineHeight: "44px",
    marginRight: 10,
    boxShadow:
      "2px 2px 5px rgba(80, 178, 125, 0.51), -2px -2px 4px rgba(255, 255, 255, 0.9)",
    borderRadius: "23px",
    backgroundColor: theme.colors.primaryGreen,
    cursor: "pointer",
    "& i": {
      "&:before": {
        color: "white",
        fontSize: 26,
        position: "relative",
        top: 7,
      },
    },
  },
  searchInput: {
    outline: "none !important",
    flex: 1,
    boxShadow:
      "inset 2px 2px 2px rgba(166, 171, 189, 0.5), inset -2px -2px 3px #fafbff",
    borderRadius: "26px",
    border: "1px solid rgba(60, 205, 88, 0.0)",
    height: "46px",
    color: theme.colors.primaryGrey,
    fontSize: "12px",
    fontWeight: 400,
    letterSpacing: "0.92px",
    paddingLeft: "25px",
    "&:focus": {
      outline: "none !important",
      border: "1px solid rgba(60, 205, 88, 0.5)",
      boxShadow:
        "inset 2px 2px 2px rgba(166, 171, 189, 0.5), inset -2px -2px 3px #fafbff",
    },

    "&:active": {
      outline: "none !important",
      border: "1px solid rgba(60, 205, 88, 0.5)",
      boxShadow:
        "inset 2px 2px 2px rgba(166, 171, 189, 0.5), inset -2px -2px 3px #fafbff",
    },
  },
}))

type Items = {
  id: number
  Name: string
}

type TabsProps = {
  items: Items[]
  defaultActiveId?: number
  onChange?: Function
  withSearch?: Boolean
  onSearchChange?: Function
  isSticky?: Boolean
}

const Tabs: React.FC<TabsProps> = ({
  items,
  defaultActiveId,
  onChange,
  withSearch,
  onSearchChange,
  isSticky,
}) => {
  const [activeItem, setActiveItem] = useState(defaultActiveId || null)
  const [searchIsActive, toggleSearch] = useState(false)
  const classes = styles(isSticky)
  const { t } = useTranslation()

  const handleOnClick = (id: number) => {
    setActiveItem(id)
    if (onChange) {
      onChange(id)
    }
  }

  useEffect(() => {
    if (onChange) {
      onChange(activeItem)
    }
  }, [activeItem])

  const onSearchChangeFn = string => {
    onSearchChange(string)
  }

  const toggleSearchMenu = () => {
    if (searchIsActive) {
      onSearchChange(null)
      toggleSearch(false)
      return
    }

    toggleSearch(true)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.listWrapper}>
        {searchIsActive && (
          <div className={classes.searchWrapper}>
            <input
              onChange={e => onSearchChangeFn(e.target.value)}
              className={classes.searchInput}
            />
            <div className={classes.search} onClick={() => toggleSearchMenu()}>
              {searchIsActive ? "X" : <i className="icon-search"></i>}
            </div>
          </div>
        )}

        <ul className={classes.list}>
          <li
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
              handleOnClick(null)
            }
            className={classes.item + (activeItem === null ? " active" : "")}
          >
            {t("SHOW_ALL")}
          </li>
          {items.map(item => (
            <li
              key={item.id}
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
                handleOnClick(item.id)
              }
              className={
                classes.item + (item.id === activeItem ? " active" : "")
              }
            >
              {item.Name}
            </li>
          ))}
          {withSearch && (
            <li className={classes.search} onClick={() => toggleSearchMenu()}>
              <i className="icon-search"></i>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Tabs
